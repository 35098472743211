@tailwind base;
@tailwind components;
@tailwind utilities;

.start-signup-button {
  /* Used for GTM tracking */
}

@layer base {
  :root {

    --radius: 0.5rem
  }
}
